<template>
  <div class="search-container">
    <div class="form-container">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" @submit.native.prevent="search">
        <el-form-item prop="keyword">
          <div class="search-item">
            <el-input class="search-input" v-model="ruleForm.keyword" placeholder="请输入所在省市区、频道名称、域名、IP等查询酒店源或组播源" @keyup.enter.native="search"></el-input>
            <el-button class="search-button" icon="el-icon-search" type="primary" v-throttle="search">搜索</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 加载状态 -->
    <el-skeleton class="search-tags-loading" v-if="loading" :loading="true" :rows="3" animated></el-skeleton>
    <!-- 加载完毕 -->
    <div class="search-tags-container" v-for="(values, name) in keywordsData" :key="name">
      <div class="section-container">
        <div class="tag-title">{{ name.includes('Hotel')? '酒店' : name.includes('Multicast')? '组播' : '热搜词' }}</div>
        <span class="tag" v-for="(item, index) in values.list" :key="index" @click="toResult(values.key, item)">{{ item.split('&')[0] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import userService from '@/api/services/userService';
export default {
  name: 'Search',
  data() {
    var validateKeyword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入你要搜索的关键词'));
      } else {
        callback();
      }
    };
    return {
      loading: false, // 控制数据的加载状态
      role: {
        name: 'guest',
        text: '游客',
        level: 1
      }, // 用户默认角色
      ruleForm: {
        keyword: '',
      },
      rules: {
        keyword: [
          { required: true, validator: validateKeyword, trigger: 'blur' }
        ],
      },
      // 关键词初始数据
      keywordsData: [
      ],
    };
  },
  mounted() {
    // 初始化时加载数据
    this.getData();
  },
  methods: {
    // 获取数据
    getData() {
      // 数据加载中
      this.loading = true;
      // 通过 userService 的 search 方法获取搜索关键词
      userService.getKeywords().then(response => {
        // 从响应中获取必要的信息
        this.keywordsData = response.data;
      })
      .catch(error => {
        console.error(error); // 捕获并输出错误
      })
      .finally(() => {
        // 数据加载完毕
        this.loading = false;
      });
    },
    // 跳转到结果页面
    toResult(key, keyword) {
      // 跳转到路径为 /result/search 的路由，并传递 key 和 keyword 参数
      router.push({ path: '/result/search', query: { key, keyword } });
    },
    // 搜索
    search() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.keywordsData['Hot Search'] !== undefined) {
            this.toResult(this.keywordsData['Hot Search'].key, this.ruleForm.keyword);
          } else {
            this.$message.error('搜索失败，请稍后再试');
          }
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.search-container ::v-deep {
  margin-top: 200px;

  /* 移动端样式（小于 768px） */
  @media screen and (max-width: 767px) {
    /* 表单宽度 */
    .form-container {
      width: 300px;
    }
  }

  /* PC端样式（大于等于 768px） */
  @media screen and (min-width: 768px) {

    /* 表单宽度 */
    .form-container {
      width: 600px;
    }
  }

  .form-container {
    margin: 0 auto;
    .search-item {
      display: flex;
      justify-content: center;
      align-items: center;

      .search-input {
        line-height: 1.15;
        .el-input__inner {
          font-size: 16px;
          border: 1px solid #BBB;
          border-radius: 20px 0 0 20px;
        }
      }

      .search-button {
        width: 100px;
        padding: 10px;
        line-height: 1.15;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #409EFF;
        border-radius: 0 20px 20px 0;
      }
    }
  }
  .search-tags-loading {
    width: 80%;
    margin: 0 auto;
    padding: 30px 0;
  }
  .result-container {
    .section-container {
      .tag-title {
        font-size: 15px;
      }
    }
  }
  .search-tags-container {
    &::after {
      content: "";
      display: table;
      clear: both;
    }
    .section-container {
      float: left;
      margin: 20px;
      padding: 30px 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px 1px rgba(64, 158, 255, 0.5);
      &::after {
        content: "";
        display: table;
        clear: both;
      }
      .tag-title {
        width: 200px;
        margin-top: -40px;
        margin-bottom: 10px;
        padding-left: 10px;
        text-align: left;
        font-size: 20px;
        color: #409EFF;
      }
      .tag {
        float: left;
        padding: 5px 10px;
        margin: 5px 10px 5px 0;
        font-size: 14px;
        color: #409EFF;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 0 0 10px 1px rgba(64, 158, 255, 0.5);
        &:hover {
          background-color: #B3D4FC;
        }
      }
    }
  }

}
</style>
